<template>
  <div class="zoom">
      <ul class="z-main">
          <li class="btn-in" @click="increase">
              <i class="el-icon-zoom-in"></i>
          </li>
          <li class="btn-reset" @click="reset">
              <span>Reset</span>
          </li>
          
          <li class="btn-out" @click="reduce">
              <i class="el-icon-zoom-out"></i>
          </li>
          <!-- <li class="btn-tool" @click="restore">
              <span>复位</span>
          </li> -->
      </ul>
  </div>
</template>


<script>
export default {

  data() {
    return {
      
    };
  },
  methods:{
    //重置
    reset(){ 
        this.$emit('onReset')
    },
    //放大事件
    increase(){ 
        this.$emit('onIncrease')
    },
    //缩小事件
    reduce(){ 
        this.$emit('onReduce')
    },
    //复位
    restore(){
        this.$emit('onRestore')
    }
  }
};
</script>

<style scoped>
    .zoom{
        display: inline-block;
        cursor: pointer;
    }
    .zoom .z-main{
        width: 178px;
        height: 38px;
        border-radius: 4px;
        background: #FFFFFF;
        font-weight: bold;
        color: #0D0D0D;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .zoom .btn-in, .zoom .btn-out, .zoom .btn-reset, .btn-tool{
        width: 25%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .zoom .btn-reset{
        width: 40%;
    }
    .zoom .btn-tool{
        width: 30%;
        font-size: 10px;
        border-left: 1px solid #0D0D0D;
    }
</style>